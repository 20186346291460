export const API_URL = 'https://api.rc.ibe.airintservices.com';
export const API_VERSION = '';
export const API_PREFIX = '/api';
export const FILE_API_URL = 'https://api.rc.ibe.airintservices.com/files/';
export const WR_SUBDIR = '/';

export const API_BASE = `${API_URL}${API_VERSION}`;
export const API = `${API_URL}${API_VERSION}${API_PREFIX}`;

export const DEVICE_ID = {
  udid: 'webreporting',
  deviceName: 'webreporting',
};

export const PAGINATION = 25;

export const LOGIN_ENV = 'prod'; // Can be 'prod' or 'test'. Used for third party SAML login.
export const URI = {
  auth: {
    saudia: `https://api.rc.ibe.airintservices.com/auth/saudia/wrlogin-react`,
    ibe: `https://api.rc.ibe.airintservices.com/auth/ibe/wrlogin-react`,
    ely: `${API_URL}/auth/ely/wrlogin-react`,
  },
};

export const ENDPOINTS = {
  status: { path: '/status', base: true },
  auth: {
    online: {
      path: '/auth/online',
      base: true,
    },
    userinfo: {
      path: '/auth/userinfo',
      base: true,
    },
    profile: '/webreporting/profile',
  },
  devices: {
    delete: '/webreporting/device/delete',
    devices: '/webreporting/devices',
    synchros: '/webreporting/device/synchronisations',
  },
  documents: {
    documentList: '/webreporting/documents',
    requestRevisions: '/webreporting/document/revision',
    history: '/webreporting/document/history',
  },
  admin: {
    users: '/webreporting/users',
    profiles: '/webreporting/profiles',
    synchros: '/webreporting/user/synchronisations',
    customerTitles: '/webreporting/usertitles',
    createUser: '/webreporting/user/create',
    adminUserEdit: '/webreporting/user/admin-edit',
  },
  sync: {
    syncId: '/sync/id',
    userdata: '/sync/userdata',
  },
  webreporting: {
    check: '/webreporting/check',
    customer: '/webreporting/customer',
    requests: '/webreporting/requests',
    filters: '/webreporting/filters',
    data: '/webreporting/data',
    columns: '/webreporting/columns',
    breadcrumbAdd: '/webreporting/breadcrumb/add',
    breadcrumbDelete: '/webreporting/breadcrumb/delete',
    attachments: '/webreporting/attachments',
    filteringvalues: '/webreporting/filteringvalues',
    aircraft: '/webreporting/aircraft',
    documents: '/webreporting/documents',
    figures: '/webreporting/figures',
    syncs: '/webreporting/syncs',
    useredit: '/webreporting/user/edit',
    updatePassword: '/webreporting/user/password/edit',
    detail: '/webreporting/detail',
    search: '/webreporting/search',
    customfields: '/webreporting/customfields',
    cfsv: '/webreporting/cfsv',
    cfsvBatch: '/webreporting/cfsvBatch',
    items: '/webreporting/items',
    notification: '/webreporting/notification',
    userManual: '/webreporting/wr-user-manual',
    requestsMaintenance: '/webreporting/requests-maintenance',
  },
  report: {
    layout: '/report/summary',
    data: '/report/data',
    pdf: '/report/pdf',
    list: '/report/list',
  },
  kpi: {
    balance: '/kpi/balance',
    metric: '/kpi/metric',
    groupBy: '/kpi/groupBy',
    affectedSubAssembly: '/kpi/affectedSubAssembly',
    affected: '/kpi/affected',
    tatBySegment: '/kpi/tatBySegment/Aircraftregistration',
    tatByTime: '/kpi/tatByTime',
    openRequests: '/kpi/openRequests',
    bestEffort: '/kpi/bestEffort',
    groupByConcat: '/kpi/groupByConcat',
    latest: '/kpi/latest',
    latestrequests: '/kpi/latestRequests',
  },
  rfid: {
    assign: '/kpi/rfid/assign',
    data: '/rfid/data',
    scene: '/rfid/scene',
  },
};
